import { Dialog, Transition } from "@headlessui/react";
import { TransitionType } from "components/common/headless-ui-utils";
import Image from "next/legacy/image";
import { Fragment, ReactNode } from "react";

export type ModalProps = { isOpen: boolean; onClose: () => void; maximizeOnMobile?: boolean };
export type PanelProps = {
  title?: string;
  bigTitle?: boolean;
  onClose: () => void;
  children: ReactNode;
  withClose?: boolean;
  hideDivide?: boolean;
};

export default function Modal(props: ModalProps & PanelProps) {
  const { children, isOpen, onClose, maximizeOnMobile = false } = props;
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={onClose}>
        <Transition.Child as={Fragment} {...TransitionType.FADE}>
          <div className="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-scroll">
          <div
            className={`flex min-h-full items-center justify-center text-center ${
              maximizeOnMobile ? "sm:p-[var(--modal-external-margin)]" : "p-[var(--modal-external-margin)]"
            }`}
          >
            <Panel {...props}>{children}</Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

function Panel({ title, bigTitle = false, children, onClose, withClose = false, hideDivide = false }: PanelProps) {
  const CloseButton = () => (
    <button onClick={onClose} type="button" className="h-11 w-11 flex items-center justify-center">
      <Image src="/images/icons/close.svg" width={12} height={12} />
    </button>
  );

  return (
    <Transition.Child as={Fragment} {...TransitionType.FADE_AND_SCALE}>
      <Dialog.Panel
        className={`w-full h-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all ${
          hideDivide ? "" : "divide-y divide-ghost"
        }`}
      >
        {title ? (
          <Dialog.Title
            as="h3"
            className={`font-bold flex items-center h-[var(--modal-header-height)] px-6 ${
              bigTitle ? "text-2xl" : "text-base"
            }`}
          >
            <CloseButton />
            <div className="flex-grow text-center">{title}</div>
            <div className="h-11 w-11"></div>
          </Dialog.Title>
        ) : (
          withClose && <CloseButton />
        )}
        {children}
      </Dialog.Panel>
    </Transition.Child>
  );
}
