import { trackEventForFacebookPixel, useAnalytics } from "components/common/app/analytics-provider";
import { Button } from "components/common/button";
import { Checkboxes, Field, FieldGroup, InputCurrency } from "components/common/form";
import Modal, { ModalProps } from "components/common/modal";
import { TagInput } from "components/common/tag-input";
import { addMonths, format } from "date-fns";
import { UserAction } from "lib/analytics-utils";
import { clinicianAction } from "lib/client/api/action";
import { CountJobsResponse, JobFiltersParams } from "lib/client/api/job";
import { useSessionUser } from "lib/client/hooks/session-user";
import { getJobFiltersFromQuery, queryObjectToQueryString } from "lib/query-helper";
import { LocationCategories } from "lib/types";
import { deleteUndefined, range } from "lib/utils";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import useSWR from "swr";

type JobFilterDialogProps = {
  onChange: (data: Partial<JobFiltersParams>) => void;
  defaultValues?: Partial<JobFiltersParams>;
} & ModalProps;

type JobFilterDialogData = Omit<Omit<JobFiltersParams, "minPay">, "maxPay"> & {
  minPay: number | null;
  maxPay: number | null;
};

const defaultValues = {
  specialties: [],
  states: [],
  minPay: null,
  maxPay: null,
  startDate: [],
  jobDuration: [],
};

export default function JobFilterDialog({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const { push, query, isReady, pathname } = useRouter();
  const { userData } = useSessionUser();
  const analytics = useAnalytics();

  const [filters, setFilters] = useState<Partial<JobFiltersParams>>({});
  const [initialized, setInitialized] = useState(false);

  const onJobFilterDialogChange = async (filters: Partial<JobFiltersParams>) => {
    push({ pathname: "/", query: { ...query, ...filters } });

    await clinicianAction(UserAction.JOB_SEARCHED, userData, { ...query, ...filters });

    if (userData) {
      trackEventForFacebookPixel(analytics, UserAction.FB_PIXEL_JOB_SEARCHED, userData.id, userData);
    }
  };

  useEffect(() => {
    if (isReady) {
      const queryFilters = getJobFiltersFromQuery(query);
      deleteUndefined(queryFilters);
      setFilters(queryFilters);
      setInitialized(true);
    }
  }, [isReady, query]);

  if (!initialized) return null;

  return (
    <JobFilterDialogContent
      isOpen={isOpen}
      onClose={onClose}
      onChange={onJobFilterDialogChange}
      defaultValues={filters}
    />
  );
}

function JobFilterDialogContent(props: JobFilterDialogProps) {
  const { onChange, ...modalProps } = props;
  const form = useForm<JobFilterDialogData>({
    mode: "onBlur",
    defaultValues: { ...defaultValues, ...props.defaultValues },
  });
  const {
    reset,
    handleSubmit,
    getValues,
    watch,
    trigger,
    control,
    formState: { isSubmitting, errors },
  } = form;

  useEffect(() => {
    trigger("minPay");
  }, [watch("maxPay")]);

  const [isCounting, setIsCounting] = useState(false);
  const [countQueryFilters, setCountQueryFilters] = useState<string | null>(null);
  const { data: countJobsData } = useSWR<CountJobsResponse>(
    props.isOpen ? `/api/jobs?count=true&${countQueryFilters}` : null
  );
  const [previousCount, setPreviousCount] = useState<number | null>(null);

  const formValues = useWatch({ control });

  const { isReady, query, pathname } = useRouter();

  useEffect(() => {
    if (isReady) {
      const jobFilters = getJobFiltersFromQuery(query);

      const queryValues = {} as Partial<JobFiltersParams>;
      queryValues.specialties = jobFilters.specialties ?? [];
      queryValues.states = jobFilters.states ?? [];
      queryValues.startDate = jobFilters.startDate ?? [];
      queryValues.jobDuration = jobFilters.jobDuration ?? [];
      reset({ ...defaultValues, ...props.defaultValues, ...queryValues });
    }
  }, [isReady, query]);

  useEffect(() => {
    if (!isReady) return;
    setIsCounting(true);
    const handler = setTimeout(() => {
      const queryObject = {
        ...formValues,
        ...query,
        locationCategoryId: LocationCategories[query?.locationCategoryId as keyof typeof LocationCategories],
      };
      setCountQueryFilters(queryObjectToQueryString(queryObject));
      setIsCounting(false);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [formValues, isReady, query]);

  useEffect(() => {
    if (countJobsData && previousCount !== countJobsData.count) {
      setPreviousCount(countJobsData.count);
    }
  }, [countJobsData]);

  const { data: specialtiesData } = useSWR("/api/specialties");
  const { data: statesData } = useSWR("/api/states");

  const onSubmit = async (data: JobFilterDialogData) => {
    const filters = { ...data } as Partial<JobFiltersParams>;
    if (filters.minPay === null) delete filters.minPay;
    if (filters.maxPay === null) delete filters.maxPay;
    if (query?.locationCategoryId) {
      (filters as any).locationCategoryId = query.locationCategoryId;
    }
    onChange(filters);
    props.onClose();
  };

  const startDateOptions = range(5)
    .map((i) => ({ i, date: addMonths(new Date(), i) }))
    .map(({ i, date }) => ({ label: i === 0 ? "ASAP" : format(date, "LLLL"), value: format(date, "yyyy-MM") }));

  const jobsCount = countJobsData?.count ?? previousCount ?? null;

  return (
    <Modal title="Filters" {...modalProps}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-6 overflow-y-scroll max-h-[calc(var(--vh,_1vh)_*_100_-_var(--modal-header-height)_-_var(--modal-external-margin)_*_2)]"
      >
        <FieldGroup title="Specialty">
          <Controller
            name="specialties"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <TagInput list={specialtiesData} autoFocus={true} onChange={onChange} selected={value} />
            )}
          />
        </FieldGroup>
        <FieldGroup title="Location">
          <Controller
            name="states"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <TagInput list={statesData} autoFocus={false} onChange={onChange} selected={value} />
            )}
          />
        </FieldGroup>
        <FieldGroup title="Pay Range">
          <div className="grid grid-flow-row sm:grid-flow-col gap-4">
            <Field
              name="minPay"
              errors={errors}
              className="h-auto"
              customErrorMessages={{ validate: "Min pay should be less than max pay" }}
            >
              <div className="w-full">
                <div className="text-silver text-xs">min pay</div>
                <InputCurrency
                  form={form}
                  name="minPay"
                  rules={{
                    validate: (value: number) => {
                      const maxPay = getValues("maxPay");
                      if (maxPay === null) return;
                      if (value === undefined || maxPay === undefined) return true;

                      return value <= maxPay;
                    },
                  }}
                />
              </div>
            </Field>
            <Field name="maxPay" className="h-auto">
              <div className="w-full">
                <div className="text-silver text-xs">max pay</div>
                <InputCurrency form={form} name="maxPay" />
              </div>
            </Field>
          </div>
        </FieldGroup>
        <FieldGroup title="Start Date">
          <div className="grid grid-rows-3 grid-flow-col gap-4">
            <Checkboxes<JobFilterDialogData> name="startDate" control={form.control} options={startDateOptions} />
          </div>
        </FieldGroup>
        <FieldGroup title="Job Duration" separator={false}>
          <div className="grid grid-rows-3 grid-flow-col gap-4">
            <Checkboxes<JobFilterDialogData>
              name="jobDuration"
              control={form.control}
              options={[
                { label: "3 weeks", value: "3" },
                { label: "4 weeks", value: "4" },
                { label: "8 weeks", value: "8" },
                { label: "13 weeks", value: "13" },
                { label: "16 weeks", value: "16" },
                { label: "Full Time", value: "17" },
              ]}
            />
          </div>
        </FieldGroup>
        <div className="h-12"></div>
        <div className="border-ghost border-t flex items-center px-6 py-3 justify-between absolute left-0 right-0 bottom-0 bg-white">
          <Button
            label="Clear all"
            isLoading={isSubmitting}
            onClick={() => reset(defaultValues)}
            className="w-auto grow-0 whitespace-nowrap px-0 underline"
            textColor="text-black"
            bgColor="bg-transparent"
          />

          <div className="w-full grow-1"></div>
          <Button
            label={`Show ${jobsCount === null || jobsCount > 100 ? "100+" : jobsCount} jobs`}
            type="submit"
            isLoading={isCounting || isSubmitting}
            onClick={handleSubmit(onSubmit)}
            className="w-auto grow-0 whitespace-nowrap px-6"
          />
        </div>
      </form>
    </Modal>
  );
}
