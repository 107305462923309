import { loginWithEmail } from "lib/client/api/auth";
import { useConversations } from "lib/client/hooks/api/use-conversations";
import { useSessionUser } from "lib/client/hooks/session-user";
import { useState } from "react";
import BackgroundGradient from "./background-gradient";
import { GlobalNav, GlobalNavConfig } from "./global-nav";
import HtmlHead, { HtmlMeta } from "./html-head";
import { MessageNotification, useShowMessageNotification } from "./message-notification";
import { UnexpectedError, useUnexpectedError } from "./unexpected-error";

export default function Layout({
  children,
  centered = false,
  noGradient = false,
  supressEmailVerificationMessage = false,
  globalNavConfig,
  isLoading = false,
  meta,
}: {
  children: React.ReactNode;
  centered?: boolean;
  noGradient?: boolean;
  supressEmailVerificationMessage?: boolean;
  globalNavConfig?: GlobalNavConfig;
  isLoading?: boolean;
  meta?: HtmlMeta;
}) {
  const [unexpectedError, setUnexpectedError] = useUnexpectedError();

  const { userData } = useSessionUser();
  const displayEmailVerificationMessage = !supressEmailVerificationMessage && userData && !userData.emailVerified;

  const { conversations } = useConversations();
  const { showMessageNotification, setShowMessageNotification, conversation } =
    useShowMessageNotification(conversations);

  const showGlobalNav = globalNavConfig?.hideGlobalNav !== true;

  return (
    <>
      <div>
        <div>
          <HtmlHead meta={meta} />
          {displayEmailVerificationMessage && <EmailVerificationMessage />}

          {showGlobalNav && <GlobalNav config={globalNavConfig} />}
          <main>
            {!noGradient && <BackgroundGradient />}

            {centered ? (
              <div
                className={`container md:mt-[var(--navbar-header-bottom-gap-md)] mx-auto flex justify-center max-w-screen-2xl`}
              >
                <div className="w-full md:w-[50rem]">{!isLoading && children}</div>
              </div>
            ) : (
              !isLoading && children
            )}

            <MessageNotification
              show={showMessageNotification && !!conversation}
              conversation={conversation}
              onHide={() => {
                setShowMessageNotification(false);
              }}
            />
          </main>
        </div>
      </div>
      {unexpectedError && <UnexpectedError onClose={() => setUnexpectedError(null)} />}
    </>
  );
}

function EmailVerificationMessage() {
  const [emailSent, setEmailSent] = useState(false);
  const { userData } = useSessionUser();

  return (
    <div className="flex items-center justify-center bg-primary text-white font-medium py-1">
      <div className="text-center md:text-left">
        <span>
          Verify your email address to <br className="md:hidden" />
          activate your account. <br className="md:hidden" />
        </span>
        <span className="block pt-2 md:inline md:pt-0">
          {emailSent ? (
            <span>A new verification email was sent.</span>
          ) : (
            userData?.email && (
              <>
                Didn't get an email?{" "}
                <a
                  className="underline cursor-pointer"
                  onClick={async () => {
                    await loginWithEmail(userData.email);

                    setEmailSent(true);
                  }}
                >
                  Resend verification email
                </a>
              </>
            )
          )}
        </span>
      </div>
    </div>
  );
}
