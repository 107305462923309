import { Transition } from "@headlessui/react";
import { TransitionType } from "components/common/headless-ui-utils";
import { ClinicianConversation } from "lib/client/api/conversations";
import { useSessionUser } from "lib/client/hooks/session-user";
import { checkAndSendNotification } from "lib/notifications-utils";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, useEffect, useRef, useState } from "react";
import { ConversationAvatar } from "../../avatar/avatar";

const NEW_MESSAGE_NOTIFICATION_TIMEOUT = 5000;
let notificationSent = false;

export function MessageNotification({
  show,
  conversation,
  onHide,
}: {
  show: boolean;
  conversation: ClinicianConversation | undefined;
  onHide?: () => void;
}) {
  return (
    <Transition appear show={show}>
      <Transition.Child as={Fragment} {...TransitionType.SLIDE_DOWN}>
        <div className="z-50 fixed top-4 inset-x-0 mx-auto w-full px-4 md:w-96 md:px-0">
          {conversation && (
            <Link
              href={`/messages/${conversation.id}`}
              onClick={onHide}
              className="block bg-black text-white py-3 px-4 rounded-2xl"
            >
              <div className="w-full flex gap-2">
                <div className="flex items-center shrink-0">
                  <ConversationAvatar sender={conversation.recruiter} />
                </div>
                <div className="flex flex-col leading-5 font-medium truncate flex-grow">
                  <div className="truncate">New message from {conversation.recruiter?.firstName}</div>
                  <div className="italic text-white/80">Reply now</div>
                </div>
                <div className="flex items-center shrink-0">
                  <Image src="/images/icons/return-white.svg" width={16} height={16} />
                </div>
              </div>
            </Link>
          )}
        </div>
      </Transition.Child>
      <div className="z-40 fixed inset-0 md:hidden" onClick={onHide}></div>
    </Transition>
  );
}

export function useShowMessageNotification(conversations: ClinicianConversation[] | undefined) {
  const [mostRecentConversation, setMostRecentConversation] = useState<ClinicianConversation | undefined>(undefined);
  const [showMessageNotification, setShowMessageNotification] = useState(false);
  const timer = useRef<number>();
  const previousRecentConversation = useRef<ClinicianConversation | {} | undefined>(undefined);
  const [wasEmpty, setWasEmpty] = useState(false);
  const { isAuthenticated } = useSessionUser();
  const router = useRouter();

  const navigateToConversation = (conversationId: number) => {
    if (conversationId) {
      router.push(`/messages/${conversationId}`);
    }
  };

  useEffect(() => {
    if (!conversations) return;

    if (!conversations.length) {
      setWasEmpty(true);
      return;
    }

    if (
      mostRecentConversation &&
      new Date(mostRecentConversation.lastMessageAt) >= new Date(conversations[0].lastMessageAt)
    )
      return;

    previousRecentConversation.current = wasEmpty ? {} : mostRecentConversation;
    setMostRecentConversation(conversations[0]);
  }, [conversations]);

  useEffect(() => {
    const isFirstRender = !previousRecentConversation.current;
    if (isFirstRender) return;

    if (!mostRecentConversation || !mostRecentConversation.theirs) return;

    notificationSent = checkAndSendNotification(isAuthenticated, mostRecentConversation, notificationSent, () =>
      navigateToConversation(mostRecentConversation.id)
    );

    clearTimeout(timer.current);
    setShowMessageNotification(true);
    timer.current = window.setTimeout(() => {
      setShowMessageNotification(false);
      notificationSent = false;
    }, NEW_MESSAGE_NOTIFICATION_TIMEOUT);

    return () => clearTimeout(timer.current);
  }, [mostRecentConversation]);

  return { showMessageNotification, setShowMessageNotification, conversation: mostRecentConversation };
}
