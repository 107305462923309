import { zodResolver } from "@hookform/resolvers/zod";
import { LOGIN_REDIRECT_QUERY_PARAM, loginWithEmail, verifyUserExistence } from "lib/client/api/auth";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { BackButton, Button } from "./button";
import { Field, InputEmail, requiredEmailField } from "./form";
import Modal from "./modal";

const defaultValues = {
  email: "",
};

type LoginDialogData = typeof defaultValues;

export function LoginDialog({
  onClose,
  title,
  disableSignup = false,
}: {
  onClose: () => void;
  title?: string;
  disableSignup?: boolean;
}) {
  const [isSuccess, setIsSuccess] = useState(shouldShowLoginSuccess());

  return isSuccess ? (
    <CheckYourEmailForLoginLink onClose={onClose} />
  ) : (
    <LoginModal
      title={title}
      disableSignup={disableSignup}
      onLoginLinkSent={() => {
        setIsSuccess(true);
      }}
      onClose={onClose}
    />
  );
}

function CheckYourEmailForLoginLink({ onClose }: { onClose: () => void }) {
  return (
    <Modal onClose={onClose} isOpen={true}>
      <div className="pt-6 px-6 pb-16 flex flex-col">
        <BackButton onClick={onClose} />
        <div className="flex flex-col items-center gap-6">
          <Image src="/images/clipart/email-lock.svg" width={180} height={120} />
          <div className="text-3xl px-6 sm:px-14 font-bold text-center">Check your email for a magic login link</div>
          <div className="">Make sure to also check your spam!</div>
        </div>
      </div>
    </Modal>
  );
}

function LoginModal({
  onLoginLinkSent,
  onClose,
  disableSignup,
  title,
}: {
  onLoginLinkSent: () => void;
  onClose: () => void;
  disableSignup: boolean;
  title?: string;
}) {
  const schema = z.object({
    email: requiredEmailField(),
  });

  const form = useForm<LoginDialogData>({ mode: "onBlur", resolver: zodResolver(schema), defaultValues });
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
  } = form;

  async function onSubmit(data: LoginDialogData) {
    const {
      data: { exists, postLoginUrl },
    } = await verifyUserExistence(data.email);
    if (exists) {
      const url = postLoginUrl ?? window.location.href;
      await loginWithEmail(data.email, url);
      onLoginLinkSent();
    } else {
      form.setError("email", { type: "accountNotFound" });
    }
  }

  return (
    <Modal title={title || "Login"} onClose={onClose} isOpen={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-6 flex flex-col gap-6">
          <Field
            name="email"
            errors={errors}
            customErrorMessages={{ accountNotFound: "No account found with this email address" }}
          >
            <InputEmail form={form} name="email" rules={{ required: true }} autoFocus />
          </Field>
          <Button
            label={isSubmitting ? "Sending Link..." : "Send Login Link"}
            bigText
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
          ></Button>
          {!disableSignup && (
            <div className="bg-zinc-100 py-2 p-4 rounded-md text-center font-medium">
              Don't have an account?{" "}
              <Link href="/signup" className="text-primary">
                Signup
              </Link>
            </div>
          )}
        </div>
      </form>
    </Modal>
  );
}

export function shouldShowLoginSuccess() {
  const { query } = useRouter();
  return query[LOGIN_REDIRECT_QUERY_PARAM] === "true";
}
