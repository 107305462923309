import { Menu, Transition } from "@headlessui/react";
import { TransitionType } from "components/common/headless-ui-utils";
import JobFilterDialog from "components/job-filter-dialog/job-filter-dialog";
import { LOGIN_REDIRECT_QUERY_PARAM } from "lib/client/api/auth";
import { useUnreadConversationCount } from "lib/client/hooks/api/use-unread-conversations";
import useProfileProgress from "lib/client/hooks/profile/use-profile-progress";
import { useIsClinicianWithFinishedSignup } from "lib/client/hooks/session-user";
import { getJobFiltersFromQuery } from "lib/query-helper";
import { signOut } from "next-auth/react";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, useEffect, useMemo, useState } from "react";
import { LoginDialog, shouldShowLoginSuccess } from "../login";
import { Logo } from "../logo";
import { UnreadDot } from "../unread-dot";
import { SectionLink } from "./section-link";

export type GlobalNavConfig = {
  hideGlobalNav?: boolean;
  showGlobalFiltersMobile?: boolean;
  hideHeaderMobile?: boolean;
};

type GlobalNavProps = { config?: GlobalNavConfig };

export function GlobalNav({ config }: GlobalNavProps) {
  const [isLoginOpen, setIsLoginOpen] = useState(shouldShowLoginSuccess());
  const onLogin = () => setIsLoginOpen(true);

  const [isJobFilterDialogOpen, setIsJobFilterDialogOpen] = useState(false);

  const { push, query, pathname } = useRouter();
  const isLoggedClinician = useIsClinicianWithFinishedSignup();

  return (
    <>
      <header
        className={` -z-20 border-b border-b-[#dde1df] ${config?.hideHeaderMobile === true ? "hidden md:block" : ""}`}
      >
        <div className="container max-w-screen-2xl mx-auto flex flex-col md:flex-row min-h-[72px] flex-wrap items-center px-6 py-4 md:py-4 gap-4">
          <div className="flex justify-between w-full">
            <div className={`order-1 w-full lg:order-none flex justify-between`}>
              <Logo />
              {<MobileMenu onLogin={onLogin} />}
            </div>
            {!isLoggedClinician && (
              <div className="order-2 hidden md:flex flex-grow lg:order-none flex-row justify-end items-center gap-6 whitespace-nowrap">
                <div className="-mx-6">
                  <LoginButton onLogin={onLogin} />
                </div>
                <SignupButton />
                <div className="h-10 border-l border-ghost"></div>
                <ForEmployersButton />
              </div>
            )}
          </div>
          <div
            className={`md:flex order-3 xl:order-none w-full grow justify-center xl:absolute xl:mx-auto xl:left-0 xl:right-0 xl:z-10 pointer-events-none ${
              config?.showGlobalFiltersMobile ? "flex" : "hidden"
            }`}
          >
            <GlobalSearchButton
              onClick={() => {
                setIsJobFilterDialogOpen(true);
              }}
            />
          </div>
        </div>
      </header>
      {isLoginOpen && (
        <LoginDialog
          onClose={() => {
            const queryParams = { ...query };
            if (queryParams[LOGIN_REDIRECT_QUERY_PARAM]) {
              delete queryParams[LOGIN_REDIRECT_QUERY_PARAM];
              push(pathname, { query: queryParams });
            }

            setIsLoginOpen(false);
          }}
        />
      )}
      <JobFilterDialog isOpen={isJobFilterDialogOpen} onClose={() => setIsJobFilterDialogOpen(false)} />
    </>
  );
}

function GlobalSearchButton({ onClick }: { onClick: () => void }) {
  const { query, isReady } = useRouter();

  const appliedFiltersCount = useMemo(() => {
    if (!isReady) return 0;

    const filters = getJobFiltersFromQuery(query);
    const appliedFilters = Object.values(filters).filter((value) =>
      Array.isArray(value) ? value.length > 0 : value !== null && value !== undefined
    );

    return appliedFilters.length;
  }, [query]);

  return (
    <>
      <div className="w-full md:w-[36rem] pointer-events-auto">
        <div className="md:px-4">
          <button
            tabIndex={0}
            onClick={onClick}
            className="relative flex w-full justify-between border border-ghost rounded-full h-14 md:h-12 items-center shadow bg-white "
          >
            {appliedFiltersCount > 0 && (
              <div className="z-10 h-5 px-1.5 absolute top-0 -right-1.5 flex items-center justify-center text-xs font-bold bg-primary text-white border-2 border-white rounded-full">
                {appliedFiltersCount}
              </div>
            )}
            <div className="w-full justify-center font-medium">Search travel contracts</div>
            <div className="absolute bg-[#0a0a0a] shrink-0 rounded-full w-12 h-12 md:w-10 md:h-10 flex items-center justify-center right-1 cursor-pointer">
              <Image src="/images/mag.svg" width={18} height={18} />
            </div>
          </button>
        </div>
      </div>
    </>
  );
}

function MobileMenu({ onLogin }: { onLogin: () => void }) {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useRouter();
  const isLoggedClinician = useIsClinicianWithFinishedSignup();
  const unreadConversationsCount = useUnreadConversationCount();
  const profileProgress = useProfileProgress();
  const showDot = unreadConversationsCount > 0;

  return (
    <div className={`${isLoggedClinician ? "" : "md:hidden"} justify-end`}>
      <Transition appear show={isOpen} as={Fragment}>
        <Transition.Child as={Fragment} {...TransitionType.FADE}>
          <div className="fixed inset-0 bg-black bg-opacity-25 z-20" aria-hidden="true" />
        </Transition.Child>
      </Transition>

      <Menu>
        {({ open }) => {
          useEffect(() => {
            setIsOpen(open);
          }, [open]);

          return (
            <>
              <Menu.Button>
                <div
                  className={
                    "absolute md:w-24 w-12 md:-ml-24 -ml-12 h-12 -mt-6 rounded-full md:shadow-sm md:bg-white md:border md:border-lightGrey flex items-center justify-center z-20"
                  }
                >
                  <Image src="/images/nav/menu.svg" width={24} height={24} />
                  <UnreadDot
                    show={showDot && !open}
                    positioningClassName="top-0 right-0"
                    additionalStylingClassName="drop-shadow-lg"
                  >
                    {unreadConversationsCount}
                  </UnreadDot>
                  {isLoggedClinician && (
                    <div className="ml-3 hidden justify-center items-center md:flex">
                      <Image width={32} height={32} src={"/images/nav/profile_picture.svg"} />
                    </div>
                  )}
                </div>
              </Menu.Button>

              <Menu.Items className="focus-visible:outline-none">
                <Transition appear show={open} as={Fragment}>
                  <Transition.Child as={Fragment} {...TransitionType.FADE_AND_SCALE}>
                    <div className="absolute flex flex-col mt-8 right-4 w-64 text-xl font-medium gap-2 z-40 bg-white p-3 rounded-2xl shadow">
                      <Menu.Item>
                        <SectionLink currentRoute={pathname} href="/" label="Jobs" icon="jobs" />
                      </Menu.Item>
                      <Menu.Item>
                        <SectionLink
                          currentRoute={pathname}
                          href="/messages"
                          label="Messages"
                          icon="messages"
                          showDot={showDot}
                          unreadConversationsCount={unreadConversationsCount}
                        />
                      </Menu.Item>
                      <Menu.Item>
                        <SectionLink
                          currentRoute={pathname}
                          href="/profile"
                          label="Profile"
                          icon="profile"
                          percentage={profileProgress}
                        />
                      </Menu.Item>

                      {isLoggedClinician ? (
                        <Menu.Item>
                          <div className="mt-3">
                            <LogoutButton />
                          </div>
                        </Menu.Item>
                      ) : (
                        <>
                          <Menu.Item>
                            <div className="mt-3">
                              <SignupButton />
                            </div>
                          </Menu.Item>
                          <Menu.Item>
                            <div>
                              <LoginButton onLogin={onLogin} />
                            </div>
                          </Menu.Item>
                          <Menu.Item>
                            <div className="border-ghost border-t pt-2">
                              <ForEmployersButton />
                            </div>
                          </Menu.Item>
                        </>
                      )}
                    </div>
                  </Transition.Child>
                </Transition>
              </Menu.Items>
            </>
          );
        }}
      </Menu>
    </div>
  );
}

function SignupButton() {
  return (
    <Link
      href="/signup"
      className="bg-primary w-full h-12 md:w-32 md:h-10 rounded-full text-white flex items-center justify-center font-medium"
    >
      Signup
    </Link>
  );
}

function LoginButton({ onLogin }: { onLogin: () => void }) {
  return (
    <button
      onClick={onLogin}
      className="text-primary w-full h-12 md:w-24 md:h-10 md:text-black flex items-center justify-center font-medium"
    >
      Login
    </button>
  );
}

function LogoutButton() {
  return (
    <button
      onClick={() => signOut({ callbackUrl: "/" })}
      className="text-primary w-full h-12 md:w-24 md:h-10 md:text-black flex items-center justify-center font-medium"
    >
      Logout
    </button>
  );
}

function ForEmployersButton() {
  return (
    <Link
      href="https://hire.vettedhealth.com"
      className="w-full h-12 md:w-auto md:h-10 flex items-center justify-center font-medium"
    >
      For Employers
    </Link>
  );
}
