import Image from "next/legacy/image";
import Link from "next/link";
import { forwardRef } from "react";
import { UnreadDot } from "../unread-dot";
import ProfilePercentage from "./profile-percentage";

export interface SectionLinkProps {
  currentRoute: string;
  label: string;
  href: string;
  icon: string;
  showDot?: boolean;
  percentage?: number;
  unreadConversationsCount?: number;
}

export const SectionLink = forwardRef<HTMLAnchorElement, SectionLinkProps>((props: SectionLinkProps, ref) => {
  const { currentRoute, label, href, icon, showDot = false, percentage, unreadConversationsCount, ...rest } = props;
  const active = currentRoute === href;
  return (
    <Link
      href={href}
      ref={ref}
      className={`flex items-center gap-3 h-12 px-5 rounded-full text-xl font-medium w-full md:w-auto md:min-w-44 ${
        active ? "bg-primary/10 text-primary" : ""
      }`}
      {...rest}
    >
      <div className={`flex items-center relative shrink-0 ${active ? "block" : "hidden"}`}>
        <Image src={`/images/nav/${icon}-on.svg`} width={20} height={20} priority />
      </div>
      <div className={`flex items-center relative shrink-0 ${active ? "hidden" : "block"}`}>
        <Image src={`/images/nav/${icon}-off.svg`} width={20} height={20} priority />
        <UnreadDot show={showDot} positioningClassName="-top-3 -right-3">
          {unreadConversationsCount}
        </UnreadDot>
      </div>
      <div className="flex w-full">
        <span className="pr-4 flex-grow">{label}</span>
        {percentage !== undefined && <ProfilePercentage percentage={percentage} />}
      </div>
    </Link>
  );
});
