export default function PercentageTooltip({
  percentage = 0,
  className = "",
}: {
  percentage: number | undefined;
  className?: string;
}) {
  return (
    <div
      className={`${className} w-9 h-4 flex-shrink-0 filter drop-shadow-lg rounded-full flex items-center justify-center`}
      style={{ boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)" }}
    >
      <div className="w-9 h-4 flex-shrink-0 rounded-full border border-opacity-10 bg-white flex items-center justify-center">
        <p
          className="text-black text-xs italic font-bold leading-none"
          style={{ fontFamily: "DM Sans", fontSize: "12px" }}
        >
          {percentage}%
        </p>
      </div>
    </div>
  );
}
