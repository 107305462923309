import { isConversationUnread } from "components/conversations/conversation-card";
import { useConversations } from "./use-conversations";

export function useUnreadConversations() {
  const { conversations } = useConversations();
  const unreadMessages = conversations?.some((c) => isConversationUnread(c)) === true;
  return unreadMessages;
}

export function useUnreadConversationCount() {
  const { conversations } = useConversations();
  const unreadConversationsCount = conversations?.filter(isConversationUnread).length || 0;
  return unreadConversationsCount;
}
