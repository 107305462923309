import { MeClinicianGetResponse } from "lib/client/api/clinician/me";
import useSWR from "swr";
import { useIsClinicianWithFinishedSignup } from "../session-user";

export default function useProfileProgress() {
  const isClinician = useIsClinicianWithFinishedSignup();
  const { data: basicInfoData } = useSWR<MeClinicianGetResponse>(isClinician ? `/api/clinicians/me` : null);

  return basicInfoData?.completionPercentage;
}
