import { ConversationAvatar } from "components/avatar/avatar";
import PercentageTooltip from "components/backoffice/conversation-dashboard/percentage-tooltip";
import { Conversation } from "lib/client/api/conversations";
import { formatTimestampWeekdayOrShortDateWithoutTime } from "lib/format";
import Image from "next/image";
import Skeleton from "react-loading-skeleton";

export function ConversationCard({
  conversation,
  withProgressIndicator = false,
}: {
  conversation: Conversation | undefined;
  withProgressIndicator?: boolean;
}) {
  const sender = conversation?.clinician ?? conversation?.recruiter;
  const fullName = sender ? `${sender.firstName} ${sender.lastName}` : undefined;

  return (
    <div className={`relative flex flex-row gap-3 font-medium text-black/70`}>
      <div className="relative flex items-center group/avatar">
        {withProgressIndicator ? (
          <>
            <ConversationAvatar sender={sender} size="md" />
            {conversation?.clinician?.completionPercentage === 100 ? (
              <div className="absolute -right-3 -top-0">
                <Image src="/images/icons/completed-badge.svg" width={26} height={26} alt="Completed profile" />
              </div>
            ) : (
              <PercentageTooltip
                percentage={conversation?.clinician?.completionPercentage}
                className="absolute -top-1 -right-3"
              />
            )}
          </>
        ) : (
          <ConversationAvatar sender={sender} size="md" />
        )}
      </div>

      <div className="w-full overflow-hidden">
        {conversation ? (
          <div className="flex justify-between">
            <div className="text-black font-bold truncate">{fullName}</div>
            <div className="italic text-xs shrink-0 pl-1 pr-0.5">
              {conversation.lastMessageAt
                ? formatTimestampWeekdayOrShortDateWithoutTime(conversation.lastMessageAt)
                : "-"}
            </div>
          </div>
        ) : (
          <Skeleton />
        )}
        <div className={`truncate ${isConversationUnread(conversation) ? "text-primary" : ""}`}>
          {conversation && conversation.theirs === false ? "You: " : ""}
          {!conversation ? <Skeleton /> : conversation?.content ? conversation?.content : "-"}
        </div>
      </div>
      {conversation?.important && (
        <div className="absolute -right-3 -top-5 bg-red-400 text-white rounded-full w-5 h-5 text-xs flex items-center justify-center font-bold">
          !
        </div>
      )}
    </div>
  );
}

export function isConversationUnread(conversation?: Conversation) {
  if (!conversation) return false;

  const wasSentByTheOtherEnd = conversation.theirs;
  const isUnread = wasSentByTheOtherEnd && !conversation.readAt;
  return isUnread;
}
