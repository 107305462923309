import Image from "next/legacy/image";
import React from "react";

const BackgroundGradient = () => {
  return (
    <div className="fixed inset-0 z-[-10] overflow-hidden h-full">
      <Image
        src={"/images/background/desktop-bg.svg"}
        layout="fill"
        objectFit="cover"
        quality={100}
        alt="Background image"
      />
    </div>
  );
};

export default React.memo(BackgroundGradient);
