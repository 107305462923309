import axios from "axios";
import { UserAction } from "lib/analytics-utils";
import { UserDataInSession } from "./auth";

export async function clinicianAction(event: UserAction, userData: UserDataInSession | null, properties?: any) {
  if (!userData) return;
  if (userData.userType !== "CLINICIAN") return;

  await axios.post("/api/action", { event, properties });
}
