import { Transition } from "@headlessui/react";
import { TransitionType } from "components/common/headless-ui-utils";
import { ReactNode } from "react";

export function UnreadDot({
  show,
  borderClassName = "border-primary",
  positioningClassName = "top-0 right-0",
  additionalStylingClassName = "",
  children,
}: {
  show: boolean;
  big?: boolean;
  borderClassName?: string;
  positioningClassName?: string;
  additionalStylingClassName?: string;
  children?: ReactNode;
}) {
  return (
    <Transition show={show} {...TransitionType.FADE}>
      <div
        className={`flex justify-center items-center absolute rounded-full z-10
        ${children ? "w-auto h-6 px-2 border-2 border-white bg-primary" : "w-3 h-3 bg-white"}  
        ${borderClassName} ${positioningClassName} ${additionalStylingClassName}`}
      >
        {children && <span className="font-bold text-xs text-white">{children}</span>}
      </div>
    </Transition>
  );
}
