import { ClinicianConversationListGetResponse } from "lib/client/api/conversations";
import useSWR from "swr";
import { useIsClinicianWithFinishedSignup } from "../session-user";

export function useConversations() {
  const isClinician = useIsClinicianWithFinishedSignup();
  const { data, error, mutate } = useSWR<ClinicianConversationListGetResponse>(
    isClinician ? "/api/conversations" : null
  );
  return {
    isLoading: isClinician === undefined || (!data && !error),
    conversations: data?.conversations,
    mutateConversations: mutate,
    error,
  };
}
